import { Flex, Typography } from "antd"

export default () => {
    return <Flex vertical gap='small'>
        <Typography.Title level={4}>
            众焱文化
        </Typography.Title>
        <Typography.Text type='secondary'>
            一家深耕道家文化产业信息化与数字化领域的先锋企业，始终秉持着对传统文化的敬畏之心与传承之志，专注于探索道家文化在新时代背景下的发展新路径。
        </Typography.Text>
        <Typography.Text type='secondary'>

            公司致力于将古老而深邃的道家智慧与现代科技手段相融合，精心打造一系列具有鲜明时代特色与深厚文化底蕴的数字化道家文化品牌，旨在通过创新形式让这一传统文化瑰宝焕发新的生机与活力。
        <Typography.Text type='secondary'>
        </Typography.Text>
            在这一过程中，众焱文化不仅注重技术的革新与应用，更强调道家文化精神的内涵挖掘与传播，力求在推动道家文化和谐发展的同时，促进社会各界对道家哲学的理解、认同与尊崇。
        </Typography.Text>
        <Typography.Text type='secondary'>
            为此，公司与全国各地道教协会、知名宫观以及国学文化媒体建立了长期而稳定的合作关系，共同举办线上线下的文化交流活动、学术研讨会及公益讲座，搭建起一个集信息共享、学术交流、文化传播于一体的综合性平台。
            尤为重要的是，众焱文化在推动道家文化数字化的进程中，始终紧密围绕社会主义核心价值观，将“和谐”、“仁爱”、“尊重自然”等道家核心思想与现代社会的价值观念相结合，倡导积极向上、和谐共生的生活态度，引导人们在快节奏的现代生活中寻找心灵的归宿与平衡，为构建社会主义文化强国贡献力量。
        </Typography.Text>
    </Flex>
}