import { Flex, Typography } from "antd"

export default () => {
    return <Flex vertical gap='small'>
        <Typography.Title level={5} style={{ opacity: 0.8 }}>道医</Typography.Title>
        <Typography.Text>
            是以老子《道德经》的“道”为基本理论，以老子《道德经》的“道”为核心内容，以形神兼治为手段的医学及发展出来的道医学流派。
        </Typography.Text>
        <Typography.Text>道医不是道教，是道教长生不死思想的理论实践促动下，由教内宫观道士学者、教外道家学者以道利生，以医济世为手段而自演化出的一支医学流派。 </Typography.Text>
        <Typography.Text>它介入于道教之中的文化思想，又有别于道教之外。 </Typography.Text>
        <Typography.Text>道医是以《道德经》中的道和黄帝内经为基本理论及阴阳五行学说为形神兼治手段。道医，不是道教。是道教长生不死思想的理论实践促动下，由教内宫观道士学者、教外道家学者以医弘教、以医传道、以医济世为手段而自然演化出的一支医学流派。 </Typography.Text>
        <Typography.Text>它介人于道教之中的文化思想，又有别于道教之外。道医包括中医。道医突出以神学思想，讲究形神兼治。而传统中医学是平衡医学，讲究标本兼治。道医既谈道教，又谈中医，自成门派，又自成体系。道医具有形神兼治、传流不离道、认为道是探索宇宙本源、道有善变性、万变性、及具备多种综合技能的特点。道医的内容十分丰富，以现代中医学为参照，将道医区分为三个不同层面，第一层面，形治部分，道医在治病防疾过程中，善于运用传统医学本草汤液、方剂（各种丸散膏）及针灸手段，这与中医学内容大致相同。第二层面，养生部分，包括导引，调息、内丹、辟谷，内视、房中等项，是道医学最具有特色的部分。第三层面，即神治部分，其内容包括道、德、符、占、签、咒、斋、祭祀、祈祷等，这一部分与人的信仰、品德、民间疗法有很密切的关系，包括在戒律、伦理和他力的范围之内。道医发展的根本特征是援道入医与援医入道。昭示了医道同源，医道一体与医道互补的道家医学传统，涤荡道医学着修养生命、济世利民的情怀。历史的道医名家有很多，如张道陵、孙思邈、杜光庭、张伯端等。代表著作有很多，如《广成先生玉函经》、《石药尔雅》、《韩氏医通》、《海药本草》等。 </Typography.Text>
    </Flex>
}