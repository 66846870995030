import book from '@/asset/book.jpg';
import { Flex, Typography } from 'antd';
export default () => {
    return <>
        <Flex vertical align='stretch' style={{
            overflow: 'clip',
            position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, opacity: 0.05
        }} >
            <img src={book} />
        </Flex>
        <Flex vertical gap='small'>
            <Typography.Title level={5} style={{ opacity: 0.8 }}>《太极八卦百鱼图》</Typography.Title>
            <Typography.Text >
                长卷是中国著名书画家关明峰的作品。该书画长卷长为39米，长卷的创作完成体现了中国道教文化的博大精深，太极是万物的根源，奥妙无穷，合古通今，应变无方。
            </Typography.Text>
        </Flex></>
}