import p1 from '@/asset/p1.jpg';
import p2 from '@/asset/p2.jpg';
import p3 from '@/asset/p3.jpg';
import { Flex, Image, Typography } from 'antd';
export default () => {
    return <Flex vertical gap='small'>
        <Typography.Title level={5} style={{opacity: 0.8}}>道教 </Typography.Title>
        <Typography.Text>
        是产生于中国的传统宗教,是把古代的神仙思想、道家学说、鬼神祭祀以及占卜、符箓、禁咒等巫术综合起来的产物,所谓"道家之术,杂而多端。"
        </Typography.Text>
        <Image preview={false} src={p1} style={{margin: "10px 0"}} />
        <Typography.Text>
        道教以"道"名教，或言内外修炼，或言符箓方术，其教义就是以"道"及"道德"为核心，认为天地万物都有"道"而派生，即所谓"一生二，二生三，三生万物"，社会人生都应法"道"而行，最后回归自然。
        </Typography.Text>
        <Image preview={false} src={p2} style={{margin: "10px 0"}}  />
        <Typography.Text>
        道教是一种多神教，沿袭了中国古代对于日月、星辰、河海山岳以及祖先亡灵都奉祖的信仰习惯，形成了一个包括天神、地祗和人鬼的复杂的神灵系统。
        </Typography.Text>
        <Image preview={false} src={p3} style={{margin: "10px 0"}}  />
        <Typography.Text>
        道教在中国发展的几千年来,形成了自己特有的文化.道教文化极其高雅,极其通俗。 亦其中一部分已演化为民间世俗，成为劳动群众精神生活的组成部分。
        </Typography.Text>
    </Flex>
}