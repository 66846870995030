import book from '@/asset/book.jpg';
import { Flex, Typography } from 'antd';
export default () => {
    return <>
        <Flex vertical align='stretch' style={{
            overflow: 'clip',
            position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, opacity: 0.05 }} >
            <img src={book} />
        </Flex>
        <Flex vertical gap='small'>
            <Typography.Title level={5} style={{opacity: 0.8}}>《太上老君说常清静经》</Typography.Title>
            <Typography.Text>
                仅三百九十一字。是道教炼养术重要资料之一。
            </Typography.Text>
            <Typography.Title level={5} style={{opacity: 0.8}}>《清静经》</Typography.Title>
            <Typography.Text >
                正文起首一句就是“大道无形”而定了全经格调。经文不讲有为的修养方法，而是要人从心地下手，以“清静”法门去澄心遣欲，去参悟大道。经中以发挥“清静”两字为主，简明地叙述了道家修心养性的基本原则。
            </Typography.Text>
        </Flex></>
}